import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { addResizeParams } from '../../../../utils/listingHelper';
import DefaultImage from '../../../DefaultImage';

const PremiumPlacementImage = (props) => {
  const { image, title, resize, altText } = props;
  const lazyLoad = false;

  const [error, setError] = useState(false);

  const errorOcurred = () => {
    setError(true);
  };
  return image && !error ? (
    <LazyLoadImage
      src={addResizeParams(image, resize)}
      alt={altText}
      onError={errorOcurred}
      className={classnames('image-results', { 'wide': false })}
      visibleByDefault={!lazyLoad}
      threshold={200}
      placeholder={<div className={classnames('preloader')}>{title}</div>}
    />
  ) : (
    <DefaultImage size="70" />
  );
};
PremiumPlacementImage.defaultProps = {
  lazyLoad: false,
  placement: {},
  resize: { width: 400, height: 267 }
};

PremiumPlacementImage.propTypes = {
  /** Make image visible by default */
  lazyLoad: PropTypes.bool,
  /** Placement information of the Premium */
  title: PropTypes.string,
  image: PropTypes.string,
  altText: PropTypes.string,
  /** Resize specifications for the image */
  resize: PropTypes.shape({
    height: PropTypes.number,
    width: PropTypes.number
  })
};

export default PremiumPlacementImage;
