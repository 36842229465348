/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PortalConfigContext } from '../../config/portal';
import { setProductImpression } from '../../store/actions/dataLayer';

import { getAssetUrl } from '../../utils/commonHelper';
import { addResizeParams } from '../../utils/listingHelper';
import { getLocaleUrl } from '../../utils/urlHelpers/boat';
import { getFSBOUrl } from '../../utils/urlHelpers/fsbo';
import { setListingClick } from '../../store/actions/dataLayer';
import { Ad } from '@dmm/react-common-components';
import { useShowAd } from '../CustomHooks/useShowAd';
import './styles.css';
import {useTPPServices} from '../../tppServices/tppDIHooks';
import { getMessages } from '../../tppServices/translations/messages';

const HeroViewer = (props) => {
  const [imageError, setImageError] = useState(false);
  const [attemptImageLoad, setAttemptImageLoad] = useState(false);
  const [image, setImage] = useState(undefined);
  const showHeroAd = useShowAd(props?.ad?.adId);
  const {tpp} = useTPPServices();
  const messages = getMessages();

  const context = useContext(PortalConfigContext);

  const handleImageLoaded = () => {
    setAttemptImageLoad(true);
  };

  const handleImageError = () => {
    setImageError(true);
  };

  useEffect(() => {
    if (props.ad && !props.isHeroAd) {
      const { imtId: id } = props.ad;
      props.setProductImpression(id, 'hero ad', undefined, 1);
    }

    // This listener is called when a slot has finished rendering.
    let { images } = props;
    setAttemptImageLoad(true);
    setImage(images[Math.round(Math.random() * (images.length - 1))]);
  }, []);

  const renderStockImage = (lazyLoad, alt) => {
    const { quickSearchBlock } = props;
    return (
      <div className={quickSearchBlock ? 'qs-block hero' : 'hero'}>
        {image && !imageError ? (
          <img
            src={getAssetUrl(image)}
            alt={alt}
            width="1440"
            height="400"
            className="hero-image"
            onLoad={handleImageLoaded}
            onError={handleImageError}
          />
        ) : null}
      </div>
    );
  };

  const linkAttributes = (ad) => {
    const externalLink = ad.externalUrl;
    const listingUrl = ad.listingUrl;
    const url = externalLink || getLocaleUrl(listingUrl, tpp);
    const doFollow = externalLink
      ? { rel: 'noopener nofollow', target: '_blank' }
      : {};
    return { url, doFollow };
  };

  const renderInfoBar = (ad) => {
    const {
      intl: { formatMessage: t }
    } = props;
    const { url: $url, doFollow: $doFollow } = linkAttributes(ad);
    return (
      <div className="hero-info-bar">
        <img
          className="hero-elements-logo"
          src={addResizeParams(
            ad.logoUrl,
            { width: 124, height: 60 },
            ad.start
          )}
          alt={ad.provisioningName}
        />
        <div className="hero-elements">
          <div className="hero-text-title">{ad.title}</div>
          <div className="hero-text-description">{`${t(
            messages.hero.heroPresentedBy
          )} ${ad.provisioningName}`}</div>
          <a
            href={$url}
            {...$doFollow}
            data-reporting-click-listing-type="hero ad"
            data-reporting-click-product-id={ad.imtId}
            data-reporting-page="1"
            data-reporting-rank=""
            data-reporting-listing-container="n/a"
            onClick={() => setListingClick(ad.imtId, 'hero ad', '', '')}
            className="hero-elements-button"
          >
            {t(messages.hero.heroLearnMore)}
          </a>
        </div>
      </div>
    );
  };

  const renderDefaultHeroAd = (alt) => {
    const currentLocale = props.intl.locale;
    const heroAdDefaultImageUrl = get(
      context,
      `languages.${currentLocale}.heroAdDefaultImageUrl`,
      getFSBOUrl()
    );
    const heroAdDefaultImageSrc = getAssetUrl(
      get(context, `languages.${currentLocale}.heroAdDefaultImageSrc`)
    );
    return (
      <div className="hero">
        {
          <a href={heroAdDefaultImageUrl}>
            <img
              src={heroAdDefaultImageSrc}
              alt={alt}
              width="1440"
              height="500"
              className="hero-image"
            />
          </a>
        }
      </div>
    );
  };

  const renderHeroAd = (ad, isNewHeroAd, alt) => {
    if (isNewHeroAd) {
      if (showHeroAd) {
        return (
          <div className="hero google-ad">
            <Ad {...ad} />
          </div>
        );
      }
      return renderDefaultHeroAd(alt);
    }
    let { resize } = props;
    const heroImageSizes = get(context, 'pages.home.heroImageSizes', {});
    const imageParams = {
      srcSet: `${addResizeParams(
        ad.photoUrl,
        heroImageSizes['500w'],
        ad.start,
        true
      )} 500w,
              ${addResizeParams(
                ad.photoUrl,
                heroImageSizes['768w'],
                ad.start,
                true
              )} 768w,
              ${addResizeParams(
                ad.photoUrl,
                heroImageSizes['1440w'],
                ad.start,
                true
              )} 1440w`,
      sizes: '(max-width: 1440px) 100vw, 1440px',
      src: addResizeParams(ad.photoUrl, resize, ad.start, true),
      alt: ad.title
    };
    const { url, doFollow } = linkAttributes(ad);
    return (
      <>
        <div
          className={classnames('hero', { 'with-ad': ad && !ad.hideInfobar })}
        >
          <a
            href={url}
            {...doFollow}
            data-reporting-click-listing-type="hero ad"
            data-reporting-click-product-id={ad.imtId}
            data-reporting-page="1"
            data-reporting-rank=""
            data-reporting-listing-container="n/a"
            onClick={() => setListingClick(ad.imtId, 'hero ad', '', '')}
          >
            <img
              {...imageParams}
              className="hero-image"
              onLoad={handleImageLoaded}
              onError={handleImageError}
            />
          </a>
          {!ad.hideInfobar && renderInfoBar(ad)}
        </div>
      </>
    );
  };

  let { lazyLoad, alt, ad, isHeroAd } = props;

  if (ad && isHeroAd) {
    return renderHeroAd(ad, isHeroAd, alt);
  }
  if (!attemptImageLoad) {
    return (
      <div className={classnames('hero', { 'with-ad': ad && !ad.hideInfobar })}>
        <div className="hero-image"></div>
      </div>
    );
  }
  if (ad) {
    return renderHeroAd(ad);
  }
  return renderStockImage(lazyLoad, alt);
};

HeroViewer.defaultProps = {
  lazyLoad: false,
  resize: {},
  isHeroAd: false
};

HeroViewer.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired
  }).isRequired,
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  /** Make image visible by default */
  lazyLoad: PropTypes.bool,
  alt: PropTypes.string.isRequired,
  ad: PropTypes.object,
  setProductImpression: PropTypes.func.isRequired,
  /** Resize specifications for the image */
  resize: PropTypes.shape({
    height: PropTypes.number,
    width: PropTypes.number
  }),
  quickSearchBlock: PropTypes.bool,
  isHeroAd: PropTypes.bool
};

export default connect(null, (dispatch) =>
  bindActionCreators(
    {
      setProductImpression
    },
    dispatch
  )
)(injectIntl(HeroViewer));
